<template>
    <Layout>
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="page-title-box">
                    <h4 class="font-size-18">{{ $t(title) }}</h4>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                    <router-link to="/markup-configurations">
                        <button class="btn btn-primary">
                            {{ $t('back') }}
                            <i class="mdi mdi-keyboard-backspace"></i>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form id="add-company-form" class="needs-validation" @submit.prevent="tooltipForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الاسم</label>
                                        <input id="validationTooltip01" v-model="data.name" type="text" class="form-control"
                                            placeholder="الاسم" :class="{
                                                'is-invalid': submitform && $v.data.name.$error,
                                            }" />
                                        <div v-if="submitform && $v.data.name.$error" class="invalid-tooltip">
                                            <span v-if="!$v.data.name.required">
                                                الاسم مطلوب.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">تاريخ البدء</label>
                                        <input id="validationTooltip01" v-model="data.start_date" type="date"
                                            class="form-control" placeholder="تاريخ البدء" :class="{
                                                'is-invalid': submitform && $v.data.start_date.$error,
                                            }" />
                                        <div v-if="submitform && $v.data.start_date.$error" class="invalid-tooltip">
                                            <span v-if="!$v.data.start_date.required">
                                                تاريخ البدء مطلوب.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">تاريخ الانتهاء</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01" v-model="data.end_date" type="date"
                                                class="form-control" placeholder="تاريخ الانتهاء" :class="{
                                                    'is-invalid': submitform && $v.data.end_date.$error,
                                                }" />
                                        </div>
                                        <div v-if="submitform && $v.data.end_date.$error" class="invalid-tooltip">
                                            <span v-if="!$v.data.end_date.required">
                                                تاريخ الانتهاء مطلوب .
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label mb-3 d-flex">مخصص ل :</label>
                                        <div class="form-check form-check-inline">
                                            <input v-model="data.for_everyone" type="radio" id="customRadioInline1"
                                                name="for_everyone" value="1" class="form-check-input" />
                                            <label class="form-check-label" for="customRadioInline1">جميع المستخدمين</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input v-model="data.for_everyone" type="radio" id="customRadioInline2"
                                                name="for_everyone" value="0" class="form-check-input" />
                                            <label class="form-check-label" for="customRadioInline2">مستخدم معين</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="data.for_everyone != 1" class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">نوع المستخدم</label>
                                        <div class="input-group">
                                            <select v-model="data.user_type" class="form-control">
                                                <option value="users">فرد</option>
                                                <option value="business">أعمال</option>
                                                <option value="passengers">ركاب</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="data.for_everyone != 1" class="col-md-6">
                                    <label class="typo__label" for="ajax">المستخدمين</label>
                                    <multiselect v-model="Countries" id="ajax" label="name" track-by="code"
                                        placeholder="Type to search" open-direction="bottom" :options="countries"
                                        :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false"
                                        :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3"
                                        :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-="true"
                                        @search-change="asyncFind">
                                        <template slot="tag" slot-scope="{ option, remove }"><span
                                                class="custom__tag"><span>{{
                                                    option.name }}</span><span class="custom__remove"
                                                    @click="remove(option)">❌</span></span></template>
                                        <template slot="clear" slot-scope="props">
                                            <div class="multiselect__clear" v-if="Countries.length"
                                                @mousedown.prevent.stop="clearAll(props.search)"></div>
                                        </template><span slot="noResult">Oops! No elements found. Consider changing the
                                            search
                                            query.
                                        </span>
                                    </multiselect>
                                </div>
                                <div class="col-md-12 mb-2">
                                    <h6>هوامش الربح</h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="domestic_flights">الرحلات الداخلية</label>
                                        <div class="input-group">
                                            <input id="domestic_flights"
                                                v-model="data.markup_config.domestic_flights.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select v-model="data.markup_config.domestic_flights.type" class="form-control">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الرحلات الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.markup_config.international_flights.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.markup_config.international_flights.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الفنادق الداخلية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.markup_config.domestic_hotels.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control" v-model="data.markup_config.domestic_hotels.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الفنادق الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.markup_config.international_hotels.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.markup_config.international_hotels.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12 mb-2">
                                    <h6>رسوم الخدمة</h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الرحلات الداخلية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.fee_config.domestic_flights.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control" v-model="data.fee_config.domestic_flights.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الرحلات الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.fee_config.international_flights.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.fee_config.international_flights.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الفنادق الداخلية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01" v-model="data.fee_config.domestic_hotels.amount"
                                                type="number" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control" v-model="data.fee_config.domestic_hotels.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">الفنادق الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.fee_config.international_hotels.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.fee_config.international_hotels.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12 mb-2">
                                    <h6>رسوم خدمات ما بعد البيع</h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">رسوم الالغاء للتذاكر
                                            الداخلية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.cancel_fees.domestic_ticket.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control" v-model="data.cancel_fees.domestic_ticket.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">رسوم الالغاء للتذاكر
                                            الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.cancel_fees.international_ticket.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.cancel_fees.international_ticket.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">رسوم التغيير للتذاكر
                                            الخارجية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.change_fees.domestic_ticket.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control" v-model="data.change_fees.domestic_ticket.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label" for="validationTooltip01">رسوم التغيير للتذاكر
                                            الداخلية</label>
                                        <div class="input-group">
                                            <input id="validationTooltip01"
                                                v-model="data.change_fees.international_ticket.amount" type="number"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3 position-relative">
                                        <label class="form-label v-hidden" for="validationTooltip01">%</label>
                                        <div class="input-group">
                                            <select class="form-control"
                                                v-model="data.change_fees.international_ticket.type">
                                                <option value="%">%</option>
                                                <option value="SAR">رس</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary float-right" type="submit">
                                {{ $t('add') }}
                                <i class="mdi mdi-plus-thick"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Multiselect from 'vue-multiselect'
import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
    minValue,
    maxValue,
    numeric,
    url,
    alphaNum,
} from "vuelidate/lib/validators";

export default {
    page: {
        title: "Add Price settings",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, Multiselect },
    data() {
        return {
            title: "Add Price settings",
            data: {
                name: "",
                start_date: null,
                end_date: null,
                for_everyone: 1,
                user_type: 'business',
                markup_config: {
                    domestic_flights: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_flights: {
                        amount: 0,
                        type: "SAR"
                    },
                    domestic_hotels: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_hotels: {
                        amount: 0,
                        type: "SAR"
                    }
                },
                fee_config: {
                    domestic_flights: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_flights: {
                        amount: 0,
                        type: "SAR"
                    },
                    domestic_hotels: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_hotels: {
                        amount: 0,
                        type: "SAR"
                    }
                },
                cancel_fees: {
                    domestic_ticket: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_ticket: {
                        amount: 0,
                        type: "SAR"
                    },
                },
                change_fees: {
                    domestic_ticket: {
                        amount: 0,
                        type: "SAR"
                    },
                    international_ticket: {
                        amount: 0,
                        type: "SAR"
                    },
                },
            },
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            Countries: [],
            countries: [],
            isLoading: false
        };
    },
    validations: {
        data: {
            name: { required },
            start_date: { required },
            end_date: { required }
        },
    },
    methods: {
        limitText(count) {
            return `and ${count} other countries`
        },
        asyncFind(query) {
            this.isLoading = true;
            // ajaxFindCountry(query).then(response => {
            //     this.countries = response
            //     this.isLoading = false
            // })
        },
        clearAll() {
            this.Countries = []
        },
        tooltipForm() {
            this.submitform = true;
            this.$v.$touch();
        },
        Jquery() {
            $(document).on('submit', '#add-company-form', function () {
                if (!that.$v.$anyError) {
                    $(this).find(":submit").attr('disabled', 'disabled');
                    const headers = {
                        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                    };
                    var url = `${that.$appUrl}/api/admin/pricing-settings/store?lang=${that.$i18n.locale}`;
                    that.axios.post(url, that.data, { headers })
                        .then(response => {
                            if (response.data.status == 1) {
                                that.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-left',
                                    duration: 2500
                                });
                                setTimeout(() => {
                                    that.$router.push(`/markup-configurations`);
                                }, 1000);
                            } else {
                                $(this).find(":submit").prop('disabled', false);
                                that.$toast.open({
                                    message: response.data.message,
                                    type: 'error',
                                    position: 'top-left',
                                    duration: 2500
                                });
                            }
                        });
                }
            });
        }
    },
    mounted() {
        that = this;
        this.Jquery();
    }
};
</script>
<style lang="scss" scoped>
.v-hidden {
    visibility: hidden;
}

input[type="number"],
input[type="date"],
input[type="text"],
select {
    border-color: rgba(120, 130, 140, .2);
    border-radius: 0;
    min-height: 2.375rem;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.input-group-text {
    border-radius: 0 !important;
    min-height: 2.375rem;
}
</style>
<style>
.multiselect__tags {
    min-height: 2.375rem !important;
    border-radius: 0px !important;
    border: 1px solid #e4e6e8 !important;
}
</style>